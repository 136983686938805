import { lazy } from 'react';
import { Navigate } from 'react-router-dom';

const AddCategory = lazy(() => import('./category/addCategory/AddCategory'));
const Category = lazy(() => import('./category/category/Category'));
const AddTag = lazy(() => import('./tags/addTag/AddTag'));
const Tags = lazy(() => import('./tags/tags/Tags'));
const AddBlog = lazy(() => import('./blog/addBlog/AddBlog'));
const Blogs = lazy(() => import('./blog/blogs/Blogs'));
const Authors = lazy(() => import('./author/authors/Authors'));
const Author = lazy(() => import('./author/author/Author'));
const MediaFiles = lazy(() => import('./media/mediaFiles/MediaFiles'));
const MediaFile = lazy(() => import('./media/mediaFile/MediaFile'));



const BlogAppConfig = {
  settings: {
    layout: {},
  },
  routes: [
    {
      path: 'blog/author',
      element: <Authors />,
      // auth: ["viewAuthor"]
    },
    {
      path: 'blog/author/:authorId',
      element: <Author />,
      // auth: ["addAuthor", "editAuthor"],
    },
    {
      path: 'blog/category',
      element: <Category />,
      auth: ["viewBlogCategory"],
    },
    {
      path: 'blog/category/:categoryId/*',
      element: <AddCategory />,
      auth: ["addBlogCategory", "editBlogCategory"],
    },
    {
      path: 'blog/tag',
      element: <Tags />,
      auth: ["viewBlogTag"],
    },
    {
      path: 'blog/tag/:tagId/*',
      element: <AddTag />,
      auth: ["editBlogTag", "editBlogTag"],
    },
    {
      path: 'blog/media',
      element: <MediaFiles />,
      // auth: ["viewMedia"],
    },
    {
      path: 'blog/media/:mediaFileId/*',
      element: <MediaFile />,
      // auth: ["mediaFile", "editMedia"],
    },
    {
      path: 'blog/all',
      element: <Blogs />,
      auth: ["viewBlog"],
    },
    {
      path: 'blog/:blogId/*',
      element: <AddBlog />,
      auth: ["addBlog", "editBlog"],
    },
    {
      path: 'blog/category',
      element: <Navigate to="category" />,
    },
  ],
};

export default BlogAppConfig;
